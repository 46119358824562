/**
 * About Streetmix (dialog box)
 *
 * Renders the "Team" dialog box.
 *
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Dialog from './Dialog'
import logo from '../../images/logo_horizontal.svg'
import mobycon from '../../images/mobycon.png'
import './AboutDialog.scss'

function TeamDialog (props) {
  return (
    <Dialog>
      {(closeDialog) => (
        <div className="about-dialog">
          <header>
            <img
              src={logo}
              alt="Streetsketch (logo)"
              className="about-dialog-logo"
            />
            <h1>
              <FormattedMessage
                id="dialogs.about.heading"
                defaultMessage="About Streetsketch."
              />
            </h1>
          </header>
          <div className="dialog-content">
            <div className="about-dialog-content">
              <div className="about-dialog-left">
                <p>
                  <FormattedMessage
                    id="dialogs.about.description"
                    defaultMessage="Design, remix, and share your street. Add bike paths, widen sidewalks or traffic lanes, learn how all of this can impact your community."
                  />
                </p>
                <p className="small">
                  Streetsketch is developed by Mobycon, based on the open source tool Streetmix. Streetmix is
                  developed originally by Code For America and is licensed under a Modified BSD License.
                  The original source code can be found on GitHub.
                </p>
              </div>
              <div className="about-dialog-right">
                <h2>Meet the team behind Streetsketch</h2>
                <p>
                  <a href="https://www.linkedin.com/in/hvanheijningen/" target="_blank" rel="noopener noreferrer">Hélène van Heijningen</a>, co-founder and project lead<br />
                  <a href="https://www.linkedin.com/in/lennartnout/" target="_blank" rel="noopener noreferrer">Lennart Nout</a>, co-founder and plangineer<br />
                  <a href="https://www.linkedin.com/in/eveline-de-jong-b71398b6/" target="_blank" rel="noopener noreferrer">Eveline de Jong</a>, illustrator and mobility consultant
                </p>
                <p>
                  If you have any questions about Streetsketch, want to get in touch with Mobycon or want to
                  share an idea on how to further improve Streetsketch, please contact Hélène and Lennart
                  via <a href="mailto:streetsketch@mobycon.nl">streetsketch@mobycon.nl</a>!
                </p>
                <hr />
                <img src={mobycon} alt="Mobycon" className="about-dialog-mobycon" />
                <p>
                  We are an independent research and consulting company with more than 30 years of experience working
                  in The Netherlands. We provide services related to managing traffic, transportation and mobility.
                  By integrating transportation systems, we are working to create healthy and livable communities
                  prepared for the future.
                </p>
              </div>
            </div>
          </div>
          <button className="dialog-primary-action" onClick={closeDialog}>
            <FormattedMessage id="btn.close" defaultMessage="Close" />
          </button>
        </div>
      )}
    </Dialog>
  )
}

export default React.memo(TeamDialog)
