export const DEFAULT_SEGMENTS = {
  false: [
    // Right-hand traffic
    {
      type: 'sidewalk',
      variant: { 'sidewalk-density': 'dense' },
      width: 8.33333
    },
    {
      type: 'bike-lane',
      variant: {
        direction: 'inbound',
        'bike-asphalt': 'red',
        'bike-type': 'step',
        elevation: 'sidewalk'
      },
      width: 6.66667
    },
    {
      type: 'sidewalk-lamp',
      variant: {
        "lamp-orientation": "both",
        "lamp-type": "standard",
      },
      width: 1.66667
    },
    {
      type: "parking-lane",
      variant: {
        "parking-lane-direction": "inbound",
        "asphalt": "regular",
        "parking-lane-orientation": "left",
        "optional-tree-type": "big"
      },
      width: 6.66667
    },
    {
      type: "drive-lane",
      variant: {
        "direction": "inbound",
        "asphalt-or-sidewalk": "bricks",
        "speed-bump": "empty",
        "car-type": "truck",
      },
      width: 10.83333
    },
    {
      type: "divider",
      variant: {
        "divider-type": "hedge"
      },
      width: 2
    },
    {
      type: "light-rail",
      variant: {
        "direction": "inbound",
        "public-transit-asphalt": "grass",
      },
      width: 10
    },
    {
      type: "transit-shelter",
      variant: {
        "transit-shelter-orientation": "both",
        "shelter": "shelter",
      },
      width: 10
    },
    {
      type: "light-rail",
      variant: {
        "direction": "outbound",
        "public-transit-asphalt": "grass",
      },
      width: 10
    },
    {
      type: "divider",
      variant: {
        "divider-type": "hedge"
      },
      width: 2
    },
    {
      type: "drive-lane",
      variant: {
        "direction": "outbound",
        "asphalt-or-sidewalk": "bricks",
        "speed-bump": "empty",
        "car-type": "car",
      },
      width: 10.83333
    },
    {
      type: "parking-lane",
      variant: {
        "parking-lane-direction": "outbound",
        "asphalt": "regular",
        "parking-lane-orientation": "right",
        "optional-tree-type": "big"
      },
      width: 6.66667
    },
    {
      type: "sidewalk-lamp",
      variant: {
        "lamp-orientation": "both",
        "lamp-type": "standard",
      },
      width: 1.66667
    },
    {
      type: "bike-lane",
      variant: {
        direction: 'outbound',
        'bike-asphalt': 'red',
        'bike-type': 'scooter',
        elevation: 'sidewalk'
      },
      width: 6.66667
    },
    {
      type: "sidewalk",
      variant: { 'sidewalk-density': 'dense' },
      width: 8.33333
    }
  ],
  true: [
    // Left-hand traffic
    {
      type: 'sidewalk',
      variant: { 'sidewalk-density': 'dense' },
      width: 8.33333
    },
    {
      type: 'bike-lane',
      variant: {
        direction: 'outbound',
        'bike-asphalt': 'red',
        'bike-type': 'step',
        elevation: 'sidewalk'
      },
      width: 6.66667
    },
    {
      type: 'sidewalk-lamp',
      variant: {
        "lamp-orientation": "both",
        "lamp-type": "standard",
      },
      width: 1.66667
    },
    {
      type: "parking-lane",
      variant: {
        "parking-lane-direction": "outbound",
        "asphalt": "regular",
        "parking-lane-orientation": "left",
        "optional-tree-type": "big"
      },
      width: 6.66667
    },
    {
      type: "drive-lane",
      variant: {
        "direction": "outbound",
        "asphalt-or-sidewalk": "bricks",
        "speed-bump": "empty",
        "car-type": "truck",
      },
      width: 10.83333
    },
    {
      type: "divider",
      variant: {
        "divider-type": "hedge"
      },
      width: 2
    },
    {
      type: "light-rail",
      variant: {
        "direction": "outbound",
        "public-transit-asphalt": "grass",
      },
      width: 10
    },
    {
      type: "transit-shelter",
      variant: {
        "transit-shelter-orientation": "both",
        "shelter": "shelter",
      },
      width: 10
    },
    {
      type: "light-rail",
      variant: {
        "direction": "inbound",
        "public-transit-asphalt": "grass",
      },
      width: 10
    },
    {
      type: "divider",
      variant: {
        "divider-type": "hedge"
      },
      width: 2
    },
    {
      type: "drive-lane",
      variant: {
        "direction": "inbound",
        "asphalt-or-sidewalk": "bricks",
        "speed-bump": "empty",
        "car-type": "car",
      },
      width: 10.83333
    },
    {
      type: "parking-lane",
      variant: {
        "parking-lane-direction": "inbound",
        "asphalt": "regular",
        "parking-lane-orientation": "right",
        "optional-tree-type": "big"
      },
      width: 6.66667
    },
    {
      type: "sidewalk-lamp",
      variant: {
        "lamp-orientation": "both",
        "lamp-type": "standard",
      },
      width: 1.66667
    },
    {
      type: "bike-lane",
      variant: {
        direction: 'inbound',
        'bike-asphalt': 'red',
        'bike-type': 'scooter',
        elevation: 'sidewalk'
      },
      width: 6.66667
    },
    {
      type: "sidewalk",
      variant: { 'sidewalk-density': 'dense' },
      width: 8.33333
    }
  ]
}
